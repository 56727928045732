import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import Main from "gatsby-singita-theme/src/components/Main"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import { Container, Box, Author } from "@singita/components"
import RichTextRenderer from "../components/RichTextRenderer"
import Link from "../components/Link"
import { mapperWithFunction, getDescription } from "../common/utils"
import ArchiveWrapper, {
  ARCHIVE_TYPES,
  FILTER_POSITIONS,
} from "../modules/ArchiveWrapper"

const DATA_MAP = {
  name: ["name"],
  image: ["fluid", "src"],
  role: ["role"],
  region: value => {
    return value.regions ? value.regions[0].headline : null
  },
  description: value => {
    return value.description ? (
      <RichTextRenderer content={value.description} />
    ) : null
  },
}

const SEO_DATA_MAP = {
  title: ["name"],
  image: ["fluid", "src"],
  role: ["role"],
  description: value => {
    return value.description
      ? getDescription(JSON.parse(value.description.raw))
      : null
  },
}

const AuthorTemplate = props => {
  const {
    data: { contentfulPerson },
  } = props

  const seoProps = useSeo(contentfulPerson, SEO_DATA_MAP)
  const authorProps = mapperWithFunction(contentfulPerson, DATA_MAP)
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.AUTHOR)

  return (
    <Main {...props}>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
      <Container pt={[15]}>
        <Box mx={[0, 0, 0, 10]}>
          <Author
            variant="extended"
            {...authorProps}
            image={
              contentfulPerson.image ? (
                <GatsbyImage fluid={contentfulPerson.image.fluid} />
              ) : null
            }
          />
        </Box>
      </Container>
      <Box pb={14}>
        <ArchiveWrapper
          type={ARCHIVE_TYPES.AUTHOR}
          filterPosition={FILTER_POSITIONS.NONE}
          isSearchable={false}
          defaultRefinement={contentfulPerson.name}
          headline={`Stories by ${contentfulPerson.name}`}
        />
      </Box>
    </Main>
  )
}
export default AuthorTemplate

export const authorQuery = graphql`
  query authorQuery($contentful_id: String) {
    contentfulPerson(contentful_id: { eq: $contentful_id }) {
      name
      role
      image {
        fluid(quality: 95, maxWidth: 470, maxHeight: 470) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        raw
      }
      regions {
        headline
      }
    }
  }
`
